/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

.toast-custom-class {
    margin-bottom: 100px;
    padding-bottom: 100px;
  }

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

.logos {
    @media (min-width: 820px) {
        max-width: 30%;
    }
}

.logos1 {
  @media (min-width: 820px) {
      max-width: 43%;
      margin-left: 20%; 
  }
}

.logoMainPage {
  @media (min-width: 820px) {
    max-width: 60%;
    margin-left: 20%; 
  }
  @media (min-width: 1300px) {
    max-width: 45%;
    margin-left: 27.5%;
}
}

.lists {
  @media (min-width: 820px) {
      max-width: 60%;
      margin-left: 20%; 
  }
  @media (min-width: 1300px) {
    max-width: 50%;
    margin-left: 25%;
}
}

  .listsLogin {
    @media (min-width: 820px) {
        max-width: 40%;
        margin-left: 30%; 
    }
  }

  .listsLoginMargin {
    @media (min-width: 820px) {
      margin-top: 10%;
    }

    @media (max-width: 819px) {
      margin-top: 40%;
    }
  }

  .listsRegisterMargin {
    @media (min-width: 820px) {
      margin-top: 5%;
      margin-bottom: 5%;
    }

    @media (max-width: 819px) {
      margin-top: 10%;
      margin-bottom: 10%;
    }
  }

  .bars {
    @media (min-width: 820px) {
        max-width: 60%;
        margin-left: 20%;
    }
  }

  .barsTab1 {
    @media (min-width: 820px) {
        max-width: 60%;
        margin-left: 20%;
    }
    @media (min-width: 1300px) {
      max-width: 50%;
      margin-left: 25%;
  }
}